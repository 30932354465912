import { Injectable } from '@angular/core'
import { AppConfigurationService } from '../../../../core/services/app-configuration.service'

@Injectable()
export class UrlService {
    constructor(private appConfigService: AppConfigurationService) {}

    private _apiUrl: string
    private get apiUrl(): string {
        if (this._apiUrl == null) {
            const appConfig = this.appConfigService.getConfig()
            this._apiUrl = `${appConfig.auctionApiUrl}${appConfig.auctionApiVirtualDirectory}`
        }
        return this._apiUrl
    }

    setApiURL(apiURL: string, apiVirutlaDirectory: string) {
        this._apiUrl = `${apiURL}${apiVirutlaDirectory}`
    }

    // Auctions
    getAuction(auctionId: number | null): string {
        return `${this.apiUrl}/auctions/${auctionId}`
    }

    getAnnouncements(auctionId: number | null): string {
        return `${this.apiUrl}/auctions/${auctionId}/notifications`
    }

    // Market operator auction
    marketOperatorAuction(auctionId: number | null | null): string {
        return `${this.apiUrl}/marketoperator/auctions/${auctionId}`
    }

    marketOperatorLot(auctionId: number | null | null, lotId: number | undefined): string {
        return `${this.apiUrl}/marketoperator/auctions/${auctionId}/lots/${lotId}`
    }

    associatedUsers(auctionId: number | null): string {
        return `${this.apiUrl}/marketoperator/auctions/${auctionId}/associated-users`
    }

    // Lots
    getLots(auctionId: number | null): string {
        return `${this.apiUrl}/auctions/${auctionId}/lots`
    }
    getLot(auctionId: number | null | null, lotId: number | undefined): string {
        return `${this.apiUrl}/auctions/${auctionId}/lots/${lotId}`
    }
    getLotSummary(auctionId: number | null | null, lotId: number | undefined): string {
        return `${this.apiUrl}/auctions/${auctionId}/lots/${lotId}/summary`
    }

    getLotAutoBids(auctionId: number | null | null, lotId: number | undefined): string {
        return `${this.apiUrl}/auctions/${auctionId}/lots/${lotId}/auto-bids`
    }

    // Users
    getUserProfile(subjectId: string): string {
        return `${this.apiUrl}/userprofiles/${subjectId}`
    }

    // Monitoring
    monitoringConnections(): string {
        return `${this.apiUrl}/monitoring/connections`
    }
}
