import { ErrorHandler, Injectable, Injector } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: any): void {
        const appSpinnerService = this.injector.get(NgxSpinnerService)
        appSpinnerService.hide()
        // router.navigate(['error']);
        // Handle error here
        throw error
    }
}
