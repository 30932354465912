import * as rg4js from 'raygun4js'
import { GlobalErrorHandler } from '../services/global-error-handler'

export class RaygunErrorHandler extends GlobalErrorHandler {
    override handleError(e: any) {
        rg4js('send', {
            error: e,
        })

        super.handleError(e)
    }
}
