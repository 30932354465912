import { BrowserModule } from '@angular/platform-browser'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatSidenavModule } from '@angular/material/sidenav'
// import { OpenIDImplicitFlowConfiguration, OidcSecurityService, OidcConfigService, AuthModule, LogLevel, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client'
import { AbstractSecurityStorage, AuthModule } from 'angular-auth-oidc-client'
// import * as rg4js from 'raygun4js'

import { AppComponent } from './app.component'
import { CoreModule } from './core/core.module'
import { AppRoutingModule } from './app-routing.module'
import { LatencyToolsModule } from './latency-tools/latency-tools.module'
import { LayoutComponent } from './_layout/layout/layout.component'
import { BtLayoutComponent } from './_layout/bt-layout/bt-layout.component'
import { ReactiveFormsModule } from '@angular/forms'
import { FormlyModule } from '@ngx-formly/core'
import { FormlyMaterialModule } from '@ngx-formly/material'
import { NgxSpinnerModule } from 'ngx-spinner'
import { HttpClientModule } from '@angular/common/http'
import { SharedModule } from 'app/shared/shared.module'
import { AuthConfigModule } from './auth-config.module'
import { AuthLocalStorageService } from 'app/core/services/auth-local-storage.service'
import { StartupClass } from 'app/core/services/app-loader.service'
import { UrlService } from 'app/modules/manage/sequential/services/url.service'
import { AppConfigurationService } from 'app/core/services/app-configuration.service'
import { Router } from '@angular/router'
import * as Sentry from '@sentry/angular-ivy'

// import { ModalModule } from 'ngx-bootstrap/modal'
@NgModule({
    declarations: [AppComponent, LayoutComponent, BtLayoutComponent],
    imports: [
        AuthConfigModule,
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        SharedModule,
        MatSidenavModule,
        LatencyToolsModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        FormlyModule.forRoot(),
        FormlyMaterialModule,
        CoreModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [StartupClass, UrlService, AppConfigurationService, Sentry.TraceService],
            useFactory: (startupClass: StartupClass) => () => startupClass.load(),
        },
        { provide: AbstractSecurityStorage, useClass: AuthLocalStorageService },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [AuthModule],
})
export class AppModule {}
