import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'

import { UrlService } from './url.service'
import { LotResponse } from '../../shared/models/lot-response.model'
import { LotSummaryResponse } from '../../shared/models/lot-summary-response.model'
import { AuctionResponse, AuctionNotificationResponse, AuctionNotification } from '../../shared/models/auction-response.model'
import { LotActivityStatus } from '../../../../core/constants'
import { ConfigurationTimings } from '../../shared/models'
import { GetAuctionMarketOperatorResponse } from '../../shared/models/get-auction-market-operator-response.model'
import { LotMarketOperatorResponse } from '../../shared/models/lot-market-operator-response.model'
import { LotMarketOperatorRequestModel } from '../../shared/models/put-lot-market-operator-request.model'
import { LotStatusMarketOperatorResponse } from '../models/lot-status-market-operator-response.model'
import { UserProfileResponse } from '../../shared/models/user-profile-response.model'
import { MonitoringConnectionsResponse } from '../models/monitoring-connections-response.model'

@Injectable()
export class ApiService {
    constructor(private http: HttpClient, private urlService: UrlService) {}

    // Auctions
    getAuction(auctionId: number | null): Observable<AuctionResponse> {
        const url = this.urlService.getAuction(auctionId)
        return this.http.get<AuctionResponse>(url)
    }

    getAuctionAnnouncements(auctionId: number | null): Observable<AuctionNotificationResponse> {
        const url = this.urlService.getAnnouncements(auctionId)
        return this.http.get<AuctionNotificationResponse>(url)
    }

    postAuctionAnnouncement(auctionId: number | null, msg): Observable<AuctionNotification> {
        let url = this.urlService.getAnnouncements(auctionId)
        url += '?Message=' + encodeURIComponent(msg)
        return this.http.post<AuctionNotification>(url, null)
    }

    postAuctionAutoBids(auctionId, lotId, amount, bidderId): Observable<any> {
        let url = this.urlService.getLotAutoBids(auctionId, lotId)
        url += `?Amount=${amount}&BidderId=${bidderId}`
        return this.http.post<any>(url, null)
    }

    deleteAuctionAutoBids(auctionId, lotId): Observable<any> {
        const url = this.urlService.getLotAutoBids(auctionId, lotId)
        return this.http.delete<any>(url)
    }

    getAuctionAutoBid(auctionId, lotId): Observable<any> {
        let url = this.urlService.getLotAutoBids(auctionId, lotId)
        url += '/current'
        return this.http.get<any>(url)
    }

    // Market operator auction
    getAuctionForMarketOperator(auctionId: number | null): Observable<GetAuctionMarketOperatorResponse> {
        const url = this.urlService.marketOperatorAuction(auctionId)
        return this.http.get<GetAuctionMarketOperatorResponse>(url)
    }

    getAssociatedUsers(auctionId: number | null) {
        const url = this.urlService.associatedUsers(auctionId)
        return this.http.get(url)
    }

    putOpenAuction(auctionId: number | null | null): Observable<GetAuctionMarketOperatorResponse> {
        const url = `${this.urlService.marketOperatorAuction(auctionId)}/open`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putStartAuction(auctionId: number | null | null): Observable<GetAuctionMarketOperatorResponse> {
        const url = `${this.urlService.marketOperatorAuction(auctionId)}/start`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putPauseAuction(auctionId: number | null | null): Observable<GetAuctionMarketOperatorResponse> {
        const url = `${this.urlService.marketOperatorAuction(auctionId)}/pause`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putNearReserveLot(auctionId: number | null | null, lotId: number | undefined, isNearReserve: boolean, timeExtension: boolean): Observable<LotMarketOperatorResponse> {
        const url = `${this.urlService.marketOperatorLot(auctionId, lotId)}/near-reserve`
        let httpParams = new HttpParams()
        httpParams = httpParams.append('IsNearReserve', isNearReserve.toString())
        httpParams = httpParams.append('ApplyTimeExtension', timeExtension.toString())
        return this.http.put<LotMarketOperatorResponse>(url, null, {
            params: httpParams,
        })
    }

    putLotClosing(auctionId: number | null | null, lotId: number | undefined, lotClosing: boolean): Observable<LotMarketOperatorResponse> {
        const url = `${this.urlService.marketOperatorLot(auctionId, lotId)}/lot-closing`
        let httpParams = new HttpParams()
        httpParams = httpParams.append('LotClosing', lotClosing.toString())
        return this.http.put<LotMarketOperatorResponse>(url, null, {
            params: httpParams,
        })
    }

    putCloseLot(auctionId: number | null | null) {
        const url = `${this.urlService.marketOperatorAuction(auctionId)}/closeLot`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putFinishLot(auctionId: number | null | null) {
        const url = `${this.urlService.marketOperatorAuction(auctionId)}/finishLot`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putStartLot(auctionId: number | null | null) {
        const url = `${this.urlService.marketOperatorAuction(auctionId)}/startLot`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putChangeIncrementLot(auctionId: number | null | null, lotId: number | undefined, lotIncrement: number, incAction: string) {
        const url = this.urlService.marketOperatorLot(auctionId, lotId)
        let httpParams = new HttpParams()
        httpParams = httpParams.append('Increment', lotIncrement.toString())
        if (incAction) {
            httpParams = httpParams.append('IncrementAction', incAction.toString())
        }
        return this.http.put(url, null, {
            params: httpParams,
        })
    }

    putChangeLotTally(auctionId: number | null | null, lotId: number | undefined, lotTally: number) {
        const url = this.urlService.marketOperatorLot(auctionId, lotId)
        let httpParams = new HttpParams()
        httpParams = httpParams.append('Tally', lotTally.toString())
        return this.http.put(url, null, {
            params: httpParams,
        })
    }

    putChangeLotWeight(auctionId: number | null | null, lotId: number | undefined, lotWeight: number) {
        const url = this.urlService.marketOperatorLot(auctionId, lotId)
        let httpParams = new HttpParams()
        httpParams = httpParams.append('AveWeight', lotWeight.toString())
        return this.http.put(url, null, {
            params: httpParams,
        })
    }

    putChangeLotStartPrice(auctionId: number | null | null, lotId: number | undefined, lotStartPrice: number) {
        const url = this.urlService.marketOperatorLot(auctionId, lotId)
        let httpParams = new HttpParams()
        httpParams = httpParams.append('StartPrice', lotStartPrice.toString())
        return this.http.put(url, null, {
            params: httpParams,
        })
    }

    putChangeLotReservePrice(auctionId: number | null | null, lotId: number | undefined, lotReservePrice: number) {
        const url = this.urlService.marketOperatorLot(auctionId, lotId)
        let httpParams = new HttpParams()
        httpParams = httpParams.append('ReservePrice', lotReservePrice.toString())
        return this.http.put(url, null, {
            params: httpParams,
        })
    }

    putUpdateLots(auctionId: number | null, lotRequest: LotMarketOperatorRequestModel): Observable<LotMarketOperatorResponse> {
        const url = `${this.urlService.marketOperatorAuction(auctionId)}/lots`
        return this.http.put<LotMarketOperatorResponse>(url, lotRequest)
    }

    putWithdrawLot(auctionId: number | null | null, lotId: number | undefined | undefined): Observable<LotStatusMarketOperatorResponse> {
        const url = `${this.urlService.marketOperatorLot(auctionId, lotId)}/status`
        return this.http.put<LotStatusMarketOperatorResponse>(url, {
            changeReason: null,
            activityStatus: LotActivityStatus.Withdrawn,
        })
    }

    putWithdrawBid(auctionId: number | null | null, lotId: number | undefined, bidId: number): Observable<any> {
        const url = `${this.urlService.marketOperatorLot(auctionId, lotId)}/withdraw-bid`
        let httpParams = new HttpParams()
        httpParams = httpParams.append('BidId', bidId.toString())

        return this.http.put<any>(url, null, { params: httpParams })
    }

    /**
     * Gets the MO auction timings
     * @param auctionId
     */
    getMarketOperatorAuctionConfiguration(auctionId: number | null): Observable<any> {
        const url = `${this.urlService.marketOperatorAuction(auctionId)}/configuration`
        return this.http.get(url)
    }

    putMarketOperatorAuctionConfiguration(auctionId: number | null, request: ConfigurationTimings): Observable<any> {
        const url = `${this.urlService.marketOperatorAuction(auctionId)}/configuration`
        return this.http.put(url, request)
    }

    postMarketOperatorAuctionConfiguration(auctionId: number | null, request: ConfigurationTimings): Observable<any> {
        const url = `${this.urlService.marketOperatorAuction(auctionId)}/configuration/pending`
        return this.http.post(url, request)
    }

    deleteMarketOperatorAuctionConfiguration(auctionId: number | null): Observable<any> {
        const url = `${this.urlService.marketOperatorAuction(auctionId)}/configuration/pending`
        let httpParams = new HttpParams()
        httpParams = httpParams.append('auctionId', auctionId!.toString())
        return this.http.delete(url, { params: httpParams })
    }

    // Lots
    getLot(auctionId: number | null | null, lotId: number | undefined): Observable<LotResponse> {
        const url = this.urlService.getLot(auctionId, lotId)
        return this.http.get<LotResponse>(url)
    }

    getLots(auctionId: number | null): Observable<LotResponse[]> {
        const url = this.urlService.getLots(auctionId)
        return this.http.get<LotResponse[]>(url)
    }

    getLotSummary(auctionId: number | null | null, lotId: number | undefined): Observable<LotSummaryResponse> {
        const url = this.urlService.getLotSummary(auctionId, lotId)
        return this.http.get<LotSummaryResponse>(url)
    }

    // Users
    getUserProfile(subjectId: string): Observable<UserProfileResponse> {
        const url = this.urlService.getUserProfile(subjectId)
        return this.http.get<UserProfileResponse>(url)
    }

    // Monitoring
    getMonitoringConnections(): Observable<MonitoringConnectionsResponse> {
        const url = this.urlService.monitoringConnections()
        return this.http.get<MonitoringConnectionsResponse>(url)
    }
}
