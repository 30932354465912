import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'

import { LotResponse } from '../../shared/models/lot-response.model'
import { LotSummaryResponse } from '../../shared/models/lot-summary-response.model'
import { AuctionResponse, AuctionNotificationResponse, AuctionNotification, AnnouncementType } from '../../shared/models/auction-response.model'
import { AppConfigurationService } from '../../../../core/services/app-configuration.service'
import { SaleyardLotMarketOperatorResponse } from '../models/saleyard-lot-market-operator-response.model'
import { SaleOrder, SaleOrderDeleteRequest } from '../models/saleyard-sale-order.model'
import { LotActivityStatus } from '../../../../core/constants'
import { GetAuctionMarketOperatorResponse } from '../../shared/models/get-auction-market-operator-response.model'
import { LotMarketOperatorResponse } from '../../shared/models/lot-market-operator-response.model'
import { LotMarketOperatorRequestModel } from '../../shared/models/put-lot-market-operator-request.model'
import { UserProfileResponse } from '../../shared/models/user-profile-response.model'
import { SaleType, SaleyardAuctionGetAllResponse } from '../models/saleyard-auction-get-all-response'
import { SaleyardAuctionGetAllRequest } from '../models/saleyard-auction-get-all-request'
import { SaleyardGetAllResponse } from '../models/saleyard-get-response.model'
import { BidPreset } from '../../shared/models'
import { SaleyardAuction } from '../models/saleyard-auction-get-all-response'
import { map } from 'rxjs/operators'
import * as moment from 'moment'
import { SaleyardAuctionLotPostSale } from '../models/saleyard-auction-lot-post-sale.model'
import { LotsBulkResponse } from '../../shared/models/lots-bulk-response'
import { SaleyardPickMarketOperatorResponse } from '../models/saleyard-pick-market-operator-response.model'
import { PresetAnnouncement } from '../models/saleyard-preset-announcement.model'

@Injectable()
export class SaleyardApiService {
    constructor(private http: HttpClient, private appConfigService: AppConfigurationService) {}

    private _apiUrl: string
    private get apiUrl(): string {
        if (this._apiUrl == null) {
            const appConfig = this.appConfigService.getConfig()
            this._apiUrl = `${appConfig.auctionApiUrl}${appConfig.auctionApiVirtualDirectory}`
        }
        return this._apiUrl
    }

    getSaleyards(): Observable<SaleyardGetAllResponse> {
        const url = this.SaleyardsUrl()
        return this.http.get<SaleyardGetAllResponse>(url)
    }

    // Auctions
    getAuctions(request: SaleyardAuctionGetAllRequest): Observable<SaleyardAuctionGetAllResponse> {
        const url = this.AuctionsUrl(request)
        return this.http.get<SaleyardAuctionGetAllResponse>(url).pipe(
            map((from: SaleyardAuctionGetAllResponse) => {
                from.auctions.forEach(a => (a.startsAt = moment.utc(a.startsAt).toDate()))
                return from
            })
        )
    }

    getAuction(auctionId: number | null): Observable<AuctionResponse> {
        const url = `${this.apiUrl}/manage-api/auctions/${auctionId}`
        return this.http.get<AuctionResponse>(url).pipe(
            map((a: AuctionResponse) => {
                a.startsAt = moment.utc(a.startsAt).toDate()
                return a
            })
        )
    }

    getAuctionSaleTypes(): Observable<SaleType[]> {
        const url = `${this.apiUrl}/manage-api/saletypes?AuctionOnly=true`
        return this.http.get<SaleType[]>(url)
    }

    postAuction(auction: SaleyardAuction): Observable<SaleyardAuction> {
        const url = `${this.apiUrl}/saleyard/auctions`
        return this.http.post<SaleyardAuction>(url, auction)
    }

    downloadAuctionResult(auctionId: number | null): Observable<Blob> {
        const url = `${this.apiUrl}/saleyard/auctions/${auctionId}/auction-results.csv`
        return this.http.get(url, {
            responseType: 'blob',
        })
    }

    putAuctionActivityStatus(auctionId: number | null, status: number, changeReason: string, startsAt: Date): Observable<any> {
        const url = `${this.apiUrl}/saleyard/auctions/${auctionId}/activity-status`
        return this.http.put(url, {
            auctionId: auctionId,
            activityStatus: status,
            changeReason: changeReason,
            startsAt,
        })
    }

    putAuctionStatus(auctionId: number | null, status: number, changeReason: string): Observable<any> {
        const url = `${this.apiUrl}/saleyard/auctions/${auctionId}/status`
        return this.http.put(url, {
            auctionId: auctionId,
            status: status,
            changeReason: changeReason,
        })
    }

    putAuction(auctionId: number | null | null, auction: SaleyardAuction): Observable<SaleyardAuction> {
        const url = `${this.apiUrl}/saleyard/auctions/${auctionId}`
        return this.http.put<SaleyardAuction>(url, auction)
    }

    getAuctionAnnouncements(auctionId: number | null): Observable<AuctionNotificationResponse> {
        const url = `${this.AuctionUrl(auctionId)}/notifications`
        return this.http.get<AuctionNotificationResponse>(url)
    }

    postAuctionAnnouncement(auctionId: number | null | null, msg: string, recipientBidderId: number | null): Observable<AuctionNotification> {
        const url = `${this.AuctionUrl(auctionId)}/notifications`
        return this.http.post<AuctionNotification>(url, {
            message: msg,
            recipientBidderId: recipientBidderId,
        })
    }

    // Market operator auction
    getAuctionForMarketOperator(auctionId: number | null): Observable<GetAuctionMarketOperatorResponse> {
        const url = this.marketOperatorAuction(auctionId)
        return this.http.get<GetAuctionMarketOperatorResponse>(url)
    }

    getAssociatedUsers(auctionId: number | null) {
        const url = `${this.apiUrl}/saleyard/marketoperator/auctions/${auctionId}/associated-users`
        return this.http.get(url)
    }

    putOpenAuction(auctionId: number | null): Observable<GetAuctionMarketOperatorResponse> {
        const url = `${this.marketOperatorAuction(auctionId)}/open`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putStartAuction(auctionId: number | null): Observable<GetAuctionMarketOperatorResponse> {
        const url = `${this.marketOperatorAuction(auctionId)}/start`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putPauseAuction(auctionId: number | null): Observable<GetAuctionMarketOperatorResponse> {
        const url = `${this.marketOperatorAuction(auctionId)}/pause`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putFinishAuction(auctionId: number | null): Observable<GetAuctionMarketOperatorResponse> {
        const url = `${this.marketOperatorAuction(auctionId)}/finish`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    // putNearReserveLot(
    //       auctionId: number | null,
    //   lotId: number,
    //   isNearReserve: boolean,
    //   timeExtension: boolean
    // ): Observable<LotMarketOperatorResponse> {
    //   const url = `${this.marketOperatorLot(auctionId, lotId)}/near-reserve`;
    //   let httpParams = new HttpParams();
    //   httpParams = httpParams.append('IsNearReserve', isNearReserve.toString());
    //   httpParams = httpParams.append('ApplyTimeExtension', timeExtension.toString());
    //   return this.http.put<LotMarketOperatorResponse>(url, null, {
    //     params: httpParams
    //   });
    // }

    putLotClosing(auctionId: number | null | null, lotId: number | undefined, lotClosing: boolean): Observable<LotMarketOperatorResponse> {
        const url = `${this.marketOperatorLot(auctionId, lotId)}/lot-closing`
        let httpParams = new HttpParams()
        httpParams = httpParams.append('LotClosing', lotClosing.toString())
        return this.http.put<LotMarketOperatorResponse>(url, null, {
            params: httpParams,
        })
    }

    putLotNowSelling(auctionId: number | null | null, lotId: number | undefined, nowSelling: boolean): Observable<LotMarketOperatorResponse> {
        const url = `${this.marketOperatorLot(auctionId, lotId)}/now-selling`
        let httpParams = new HttpParams()
        httpParams = httpParams.append('NowSelling', nowSelling.toString())
        return this.http.put<LotMarketOperatorResponse>(url, null, {
            params: httpParams,
        })
    }

    putCloseLot(auctionId: number | null) {
        const url = `${this.marketOperatorAuction(auctionId)}/closeLot`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putConfirmNextLot(auctionId: number | null | null, lotId: number | undefined) {
        const url = `${this.marketOperatorAuction(auctionId)}/confirmNextLot?LotId=${lotId}&AuctionId=${auctionId}`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putStartLot(auctionId: number | null) {
        const url = `${this.marketOperatorAuction(auctionId)}/startLot`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putLotSaleResult(auctionId: number | null, status: number) {
        const url = `${this.marketOperatorAuction(auctionId)}/setLotSaleResult?Status=${status}`
        return this.http.put<GetAuctionMarketOperatorResponse>(url, null)
    }

    putAuctionOfferManage(auctionId: number | null) {
        const url = `${this.AuctionOffersUrl(auctionId)}/manage`
        return this.http.put(url, null)
    }

    postAuctionOffer(auctionId: number | null, bidderId: number, amount: number) {
        const url = `${this.AuctionOffersUrl(auctionId)}`
        return this.http.post<any>(url, {
            auctionId,
            bidderId,
            amount,
        })
    }

    putAuctionOfferComplete(auctionId: number | null) {
        const url = `${this.AuctionOffersUrl(auctionId)}/complete`
        return this.http.put(url, null)
    }

    putAuctionFixAndOfferComplete(auctionId: number | null, auctionListingLotId: number, auctionState: number) {
        const url = `${this.AuctionOffersUrl(auctionId)}/complete/resolve`
        return this.http.put(url, {
            auctionId: auctionId,
            auctionListingLotId: auctionListingLotId,
            auctionState: auctionState,
        })
    }

    putAuctionOfferCancelling(auctionId: number | null, offerId: number) {
        const url = `${this.AuctionOffersUrl(auctionId)}/${offerId}/cancelling`
        return this.http.put(url, null)
    }

    putAuctionOfferCancelled(auctionId: number | null, offerId: number) {
        const url = `${this.AuctionOffersUrl(auctionId)}/${offerId}/cancel`
        return this.http.put(url, null)
    }

    putChangeIncrementLot(auctionId: number | null | null, lotId: number | undefined, lotIncrement: number) {
        const url = this.marketOperatorLot(auctionId, lotId)
        let httpParams = new HttpParams()
        httpParams = httpParams.append('Increment', lotIncrement.toString())
        httpParams = httpParams.append('alternatingBidPresetGroupId', null!)
        return this.http.put(url, null, {
            params: httpParams,
        })
    }

    putChangeLotTally(auctionId: number | null | null, lotId: number | undefined, lotTally: number) {
        const url = this.marketOperatorLot(auctionId, lotId)
        let httpParams = new HttpParams()
        httpParams = httpParams.append('Tally', lotTally.toString())
        return this.http.put(url, null, {
            params: httpParams,
        })
    }

    putChangeLotWeight(auctionId: number | null | null, lotId: number | undefined, lotWeight: number) {
        const url = this.marketOperatorLot(auctionId, lotId)
        let httpParams = new HttpParams()
        httpParams = httpParams.append('AveWeight', lotWeight.toString())
        return this.http.put(url, null, {
            params: httpParams,
        })
    }

    putChangeLotStartPrice(auctionId: number | null | null, lotId: number | undefined, lotStartPrice: number, initialPriceType: number) {
        const url = this.marketOperatorLot(auctionId, lotId)
        return this.http.put(url, {
            StartPrice: lotStartPrice,
            InitialPriceType: initialPriceType,
        })
    }

    putChangeLotAlternatingBidsGroup(auctionId: number | null | null, lotId: number | undefined, groupId: number) {
        const url = this.marketOperatorLot(auctionId, lotId)
        return this.http.put(url, {
            alternatingBidPresetGroupId: groupId,
        })
    }

    putChangeLotNumber(auctionId: number | null | null, lotId: number | undefined, lotNumber: string) {
        const url = this.marketOperatorLot(auctionId, lotId)
        return this.http.put(url, {
            lotNumber: lotNumber,
        })
    }

    putChangeUpcomingLotTally(auctionId: number | null | null, lotId: number | undefined, lotTally: number) {
        const url = this.lotQuickEdit(auctionId, lotId)
        let httpParams = new HttpParams()
        httpParams = httpParams.append('Tally', lotTally.toString())
        return this.http.put(url, null, {
            params: httpParams,
        })
    }

    putChangeUpcomingLotWeight(auctionId: number | null | null, lotId: number | undefined, lotWeight: number) {
        const url = this.lotQuickEdit(auctionId, lotId)
        let httpParams = new HttpParams()
        httpParams = httpParams.append('AveWeight', lotWeight.toString())
        return this.http.put(url, null, {
            params: httpParams,
        })
    }

    postBidPreset(auctionId: number | null, groupId: number, amount: number) {
        const url = `${this.AuctionUrl(auctionId)}/bidPresets`
        return this.http.post(url, {
            auctionId: auctionId,
            amount: amount,
            groupId: groupId,
        })
    }

    postLotsBulk(auctionId: number | null, fileStream: any) {
        const url = `${this.auctionSayleyardLot(auctionId)}/bulk`
        return this.http.post<LotsBulkResponse>(url, fileStream)
    }

    deleteBidPreset(auctionId: number | null, id: number) {
        const url = `${this.AuctionUrl(auctionId)}/bidPresets/${id}`
        return this.http.delete(url)
    }

    postBidPresetGroup(auctionId: number | null | null, bidPresets: BidPreset[], groupType: number, groupDirection: number) {
        const url = `${this.AuctionUrl(auctionId)}/bidPresetGroups`
        return this.http.post(url, {
            auctionId: auctionId,
            bidPresets: bidPresets,
            groupType: groupType,
            groupDirection: groupDirection,
        })
    }

    deleteBidPresetGroup(auctionId: number | null | null, id: number | null) {
        const url = `${this.AuctionUrl(auctionId)}/bidPresetGroups/${id}`
        return this.http.delete(url)
    }

    postPresetAnnouncement(auctionId: number | null | null, text: string, type: AnnouncementType) {
        const url = `${this.AuctionUrl(auctionId)}/presetAnnouncements`
        return this.http.post(url, {
            auctionId: auctionId,
            announcement: text,
            type: type,
        })
    }

    deletePresetAnnouncement(auctionId: number | null | null, id: number | null) {
        const url = `${this.AuctionUrl(auctionId)}/presetAnnouncements/${id}`
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: {
                auctionId: auctionId,
                id: id,
            },
        }
        return this.http.delete(url, httpOptions)
    }

    getPresetAnnouncements(auctionId: number | null | null): Observable<PresetAnnouncement[]> {
        const url = `${this.AuctionUrl(auctionId)}/presetAnnouncements/`
        return this.http.get<PresetAnnouncement[]>(url)
    }

    // putChangeLotReservePrice(    auctionId: number | null | null, lotId: number | undefined, lotReservePrice: number) {
    //   const url = this.marketOperatorLot(auctionId, lotId);
    //   let httpParams = new HttpParams();
    //   httpParams = httpParams.append('ReservePrice', lotReservePrice.toString());
    //   return this.http.put(url, null, {
    //     params: httpParams
    //   });
    // }

    putUpdateLots(auctionId: number | null, lotRequest: LotMarketOperatorRequestModel): Observable<LotMarketOperatorResponse> {
        const url = `${this.marketOperatorAuction(auctionId)}/lots`
        return this.http.put<LotMarketOperatorResponse>(url, lotRequest)
    }

    putLotStatus(auctionId: number | null | null, lotId: number | undefined, activityStatus: LotActivityStatus, changeReason?: string): Observable<any> {
        const url = `${this.marketOperatorLot(auctionId, lotId)}/status`

        let httpParams = new HttpParams()
        httpParams = httpParams.append('changeReason', changeReason!)
        httpParams = httpParams.append('activityStatus', activityStatus.toString())

        return this.http.put<any>(url, null, { params: httpParams })
    }

    putWithdrawBid(auctionId: number | null | null, lotId: number | undefined, bidId: number): Observable<any> {
        const url = `${this.marketOperatorLot(auctionId, lotId)}/withdraw-bid`
        let httpParams = new HttpParams()
        httpParams = httpParams.append('BidId', bidId.toString())

        return this.http.put<any>(url, null, { params: httpParams })
    }

    createPostAuctionSalePrice(auctionId: number | null, listingId: number, amount: number): Observable<any> {
        const url = `${this.apiUrl}/saleyard/auctions/${auctionId}/lots/${listingId}/PostAuctionSalePrice`
        return this.http.post<any>(url, { amount: amount })
    }

    createPostAuctionSale(auctionId: number | null, listingId: number, postAuctionSale: SaleyardAuctionLotPostSale): Observable<any> {
        const url = `${this.apiUrl}/saleyard/auctions/${auctionId}/lots/${listingId}/PostAuctionSale`
        return this.http.post<any>(url, postAuctionSale)
    }

    // Lots
    getLot(auctionId: number | null | null, lotId: number | undefined): Observable<LotResponse> {
        const url = `${this.apiUrl}/saleyard/auctions/${auctionId}/lots/${lotId}`
        return this.http.get<LotResponse>(url)
    }

    getLots(auctionId: number | null): Observable<LotResponse[]> {
        const url = `${this.apiUrl}/saleyard/auctions/${auctionId}/lots`
        return this.http.get<LotResponse[]>(url)
    }

    getLotSummary(auctionId: number | null | null, lotId: number | undefined): Observable<LotSummaryResponse> {
        const url = `${this.apiUrl}/saleyard/auctions/${auctionId}/lots/${lotId}/summary`
        return this.http.get<LotSummaryResponse>(url)
    }

    getManageLot(auctionId: number | null | null, lotId: number | undefined): Observable<SaleyardLotMarketOperatorResponse> {
        return this.http.get<SaleyardLotMarketOperatorResponse>(this.auctionSayleyardLot(auctionId) + '/' + lotId)
    }

    getManagePick(auctionId: number | null | null, lotId: number | undefined): Observable<SaleyardPickMarketOperatorResponse> {
        return this.http.get<SaleyardPickMarketOperatorResponse>(this.auctionSayleyardLot(auctionId) + '/' + lotId)
    }

    postManageLot(auctionId: number | null | null, lot: SaleyardLotMarketOperatorResponse): Observable<SaleyardLotMarketOperatorResponse> {
        return this.http.post<SaleyardLotMarketOperatorResponse>(this.auctionSayleyardLot(auctionId), lot)
    }

    postManagePick(auctionId: number | null | null, lot: SaleyardPickMarketOperatorResponse): Observable<SaleyardPickMarketOperatorResponse> {
        delete lot.lotId
        delete lot.listingId
        return this.http.post<SaleyardPickMarketOperatorResponse>(this.auctionSayleyardPick(auctionId), lot)
    }

    putManageLot(auctionId: number | null | null, lotId: number | undefined, lot: SaleyardLotMarketOperatorResponse): Observable<SaleyardLotMarketOperatorResponse> {
        return this.http.put<SaleyardLotMarketOperatorResponse>(this.auctionSayleyardLot(auctionId) + '/' + lotId, lot)
    }

    putManagePick(auctionId: number | null | null, lotId: number | undefined, lot: SaleyardPickMarketOperatorResponse): Observable<SaleyardPickMarketOperatorResponse> {
        delete lot.listingId
        delete lot.title
        return this.http.put<SaleyardPickMarketOperatorResponse>(this.auctionSayleyardPick(auctionId), lot)
    }

    getSaleyardStockTypes(forAuction: boolean) {
        return this.http.get<any>(`${this.apiUrl}/saleyard/stocktypes?forAuction=${forAuction}`)
    }

    getSaleyardStockTypeCategories(stockTypeKey: string) {
        return this.http.get<any>(this.apiUrl + '/saleyard/stocktypes/' + stockTypeKey + '/categories')
    }

    getLotsByLotNumberSearch(auctionId: number | null | null, search: string) {
        return this.http.get<any>(this.auctionSayleyardLot(auctionId) + '/exists?lotNumber=' + search)
    }

    getLotConfiguration(auctionId, stockTypeKey, stockCategory) {
        return this.http.get<any>(this.AuctionUrl(auctionId) + '/lotConfiguration?' + ['StockTypeKey=' + stockTypeKey, 'Category=' + encodeURIComponent(stockCategory)].join('&'))
    }

    getAgencies(auctionId) {
        return this.http.get<any>(this.AuctionUrl(auctionId) + '/agencies')
    }

    getAllAgencies() {
        return this.http.get<any>(this.apiUrl + '/manage-api/agencies')
    }

    getAgencyAgents(agencyId) {
        return this.http.get<any>(this.apiUrl + '/saleyard/agencies/' + agencyId + '/agents?includeOnlyUsersWithImpersonatorAccounts=true')
    }

    getAgentAccounts(agencyId, agentId) {
        return this.http.get<any>(this.apiUrl + '/saleyard/agencies/' + agencyId + '/impersonators/' + agentId + '/buyer-accounts')
    }

    getBuyersSuggestions(text, status, maxResultsCount) {
        return this.http.get<any>(this.apiUrl + '/saleyard/users/buyers?Status=' + status + '&MaxResultsCount=' + maxResultsCount + '&SearchTerm=' + text)
    }

    getBuyerAccounts(buyerId) {
        return this.http.get<any>(this.apiUrl + '/saleyard/users/buyers/' + buyerId)
    }

    // Users
    getUserProfile(subjectId: string): Observable<UserProfileResponse> {
        const url = `${this.apiUrl}/userprofiles/${subjectId}`
        return this.http.get<UserProfileResponse>(url)
    }

    getConnectedUsers(auctionId: number | null) {
        const url = `${this.apiUrl}/saleyard/marketoperator/auctions/${auctionId}/associated-users`
        return this.http.get<UserProfileResponse>(url)
    }

    // Sale Order

    getSaleOrder(auctionId: number | null): Observable<SaleOrder> {
        const url = `${this.apiUrl}/saleyard/auctions/${auctionId}/saleOrderRange`
        return this.http.get<SaleOrder>(url)
    }

    postSaleOrder(request: SaleOrder): Observable<SaleOrder> {
        const url = `${this.apiUrl}/saleyard/auctions/${request.auctionId}/saleOrderRange`
        return this.http.post<SaleOrder>(url, request)
    }

    putSaleOrder(request: SaleOrder): Observable<SaleOrder> {
        const url = `${this.apiUrl}/saleyard/auctions/${request.auctionId}/saleOrderRange`
        return this.http.put<SaleOrder>(url, request)
    }

    deleteSaleOrder(request: SaleOrderDeleteRequest) {
        const url = `${this.apiUrl}/saleyard/auctions/${request.auctionId}/saleOrderRange`
        return this.http.delete(url)
    }

    private SaleyardsUrl() {
        return `${this.apiUrl}/saleyard`
    }

    private AuctionsUrl(request: SaleyardAuctionGetAllRequest) {
        let returnUrl = `${this.apiUrl}/saleyard/auctions`

        if (request.from) {
            returnUrl = this.AppendQuerySeperator(returnUrl, 'from', request.from.toISOString())
        }
        if (request.until) {
            let untilDate = new Date(request.until)
            untilDate.setDate(untilDate.getDate() + 1)
            returnUrl = this.AppendQuerySeperator(returnUrl, 'until', untilDate.toISOString())
        }
        if (request.title) {
            returnUrl = this.AppendQuerySeperator(returnUrl, 'title', request.title)
        }
        if (request.saleyards) {
            returnUrl = this.AppendQuerySeperator(returnUrl, 'saleyards', request.saleyards.join('&saleyards='))
        }
        if (request.id) {
            returnUrl = this.AppendQuerySeperator(returnUrl, 'id', request.id.toString())
        }
        if (request.auctionStatus) {
            returnUrl = this.AppendQuerySeperator(returnUrl, 'auctionStatus', request.auctionStatus.join('&auctionStatus='))
        }
        if (request.pageNumber! > -1) {
            returnUrl = this.AppendQuerySeperator(returnUrl, 'pageNumber', request.pageNumber!.toString())
        }
        if (request.pageSize) {
            returnUrl = this.AppendQuerySeperator(returnUrl, 'pageSize', request.pageSize.toString())
        }
        if (request.sortOrder) {
            returnUrl = this.AppendQuerySeperator(returnUrl, 'sortOrder', request.sortOrder.toString())
        }

        return returnUrl
    }

    private AppendQuerySeperator(url: string, key: string, value: string) {
        url += url.indexOf('?') === -1 ? '?' : '&'
        return `${url}${key}=${value}`
    }

    private AuctionOffersUrl(auctionId: number | null) {
        return `${this.apiUrl}/saleyard/auctions/${auctionId}/offers`
    }

    private AuctionUrl(auctionId: number | null | null) {
        return `${this.apiUrl}/saleyard/auctions/${auctionId}`
    }

    private marketOperatorAuction(auctionId: number | null | null): string {
        return `${this.apiUrl}/saleyard/marketoperator/auctions/${auctionId}`
    }

    private marketOperatorLot(auctionId: number | null | null, lotId: number | undefined): string {
        return `${this.marketOperatorAuction(auctionId)}/lots/${lotId}`
    }

    private lotQuickEdit(auctionId: number | null | null, lotId: number | undefined): string {
        return `${this.auctionSayleyardLot(auctionId)}/${lotId}/quickEdit`
    }

    private auctionSayleyardLot(auctionId: number | null | null): string {
        return `${this.apiUrl}/auctions/${auctionId}/saleyard/lots`
    }

    private auctionSayleyardPick(auctionId: number | null | null): string {
        return `${this.apiUrl}/auctions/${auctionId}/saleyard/picks`
    }
}
